import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5245 8149 c-122 -7 -261 -28 -370 -55 -71 -18 -212 -61 -240 -74
-11 -5 -51 -22 -90 -39 -70 -30 -138 -64 -205 -103 -55 -33 -225 -147 -246
-166 -11 -9 -37 -32 -59 -51 -466 -394 -720 -887 -765 -1486 -46 -600 146
-1155 552 -1599 92 -100 264 -256 282 -256 7 0 40 43 75 95 l63 94 -33 28
c-187 152 -379 384 -492 593 -197 366 -262 831 -176 1277 53 280 189 566 380
798 71 87 233 247 319 316 329 262 711 390 1160 390 373 0 679 -81 974 -258
162 -97 376 -284 511 -448 355 -427 492 -1013 374 -1599 -79 -394 -290 -740
-634 -1043 l-59 -52 64 -95 c36 -53 69 -96 74 -96 5 0 37 25 72 55 529 465
781 1030 761 1705 -12 391 -85 656 -276 1005 -37 69 -184 267 -263 355 -452
506 -1061 752 -1753 709z"/>
<path d="M5313 7773 c48 -2 125 -2 170 0 45 1 6 3 -88 3 -93 0 -130 -2 -82 -3z"/>
<path d="M5613 7763 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5705 7730 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5773 7733 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4895 7715 c-25 -9 -32 -14 -19 -14 12 -1 37 6 55 14 42 18 18 18
-36 0z"/>
<path d="M5825 7720 c3 -5 21 -10 38 -9 31 1 31 1 7 9 -36 11 -52 11 -45 0z"/>
<path d="M4988 7713 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M4948 7693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5958 7683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6015 7660 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M4700 7645 c-32 -18 -25 -18 25 0 22 8 31 14 20 14 -11 0 -31 -6 -45
-14z"/>
<path d="M6065 7640 c-4 -6 -16 -10 -28 -10 -15 0 -18 -3 -10 -11 8 -8 17 -7
32 3 17 10 25 10 41 0 22 -13 50 -17 35 -4 -21 18 -64 31 -70 22z"/>
<path d="M4622 7611 c-11 -7 -11 -9 0 -14 9 -3 15 1 15 9 0 8 -1 14 -1 14 -1
0 -7 -4 -14 -9z"/>
<path d="M5280 7590 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4575 7583 c-12 -2 -34 -15 -50 -29 l-30 -25 40 21 c22 11 45 20 52
20 7 0 13 5 13 10 0 6 -1 9 -2 9 -2 -1 -12 -4 -23 -6z"/>
<path d="M5203 7583 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M5508 7583 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M5585 7580 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5620 7580 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M6161 7586 c2 -2 18 -6 34 -10 22 -5 26 -4 15 4 -14 9 -59 15 -49 6z"/>
<path d="M5128 7573 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5000 7554 l-35 -14 50 6 c28 4 54 10 60 15 14 13 -35 9 -75 -7z"/>
<path d="M5750 7551 c8 -5 33 -11 55 -14 39 -6 39 -6 15 8 -14 8 -38 14 -55
14 -21 0 -26 -2 -15 -8z"/>
<path d="M6259 7540 c13 -11 28 -20 35 -20 6 1 0 10 -13 20 -13 11 -28 20 -35
20 -6 -1 0 -10 13 -20z"/>
<path d="M4464 7522 c-9 -5 -8 -10 1 -19 11 -10 13 -9 12 7 -1 11 -1 20 -2 20
0 0 -5 -4 -11 -8z"/>
<path d="M4855 7513 c-11 -3 -26 -10 -33 -16 -7 -5 -15 -7 -19 -4 -3 4 -12 2
-19 -4 -7 -6 -25 -14 -41 -19 l-28 -8 26 -1 c14 -1 29 3 35 7 5 4 34 15 64 24
30 10 62 19 70 22 20 6 -29 5 -55 -1z"/>
<path d="M5950 7490 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M6340 7490 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M6397 7459 c7 -9 10 -19 6 -23 -4 -4 1 -4 10 0 10 3 17 7 15 8 -2 1
-12 8 -23 16 -18 14 -19 13 -8 -1z"/>
<path d="M4360 7450 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M6070 7440 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4678 7433 c-10 -2 -18 -9 -18 -14 0 -6 7 -6 18 -1 9 5 24 12 32 15
13 5 13 5 0 5 -8 0 -23 -2 -32 -5z"/>
<path d="M6105 7420 c3 -5 12 -10 19 -10 8 0 19 -6 25 -14 13 -16 54 -29 45
-15 -3 5 -10 9 -15 9 -5 0 -19 9 -31 20 -22 21 -54 28 -43 10z"/>
<path d="M6210 7415 c-14 -16 -10 -18 14 -9 9 3 16 10 16 15 0 14 -16 11 -30
-6z"/>
<path d="M6440 7422 c0 -5 11 -14 25 -20 14 -6 25 -10 25 -8 0 2 -11 11 -25
20 -14 9 -25 13 -25 8z"/>
<path d="M4287 7391 l-22 -25 27 17 c15 9 34 17 42 17 8 0 17 4 20 9 13 20
-47 4 -67 -18z"/>
<path d="M5252 7409 c-379 -40 -703 -222 -953 -534 -251 -313 -350 -733 -270
-1142 41 -211 128 -403 260 -573 47 -61 213 -230 226 -230 5 0 39 43 74 95
l64 95 -89 93 c-182 188 -276 382 -310 637 -17 136 -14 264 12 401 52 275 214
524 464 708 140 104 299 172 470 201 119 21 357 16 463 -9 279 -67 492 -205
673 -437 75 -96 154 -248 188 -360 73 -245 57 -563 -40 -797 -53 -127 -186
-314 -281 -395 l-46 -40 64 -96 c35 -52 69 -96 76 -96 14 0 134 116 200 195
138 164 241 377 288 595 24 113 31 381 11 505 -35 227 -122 440 -249 610 -163
218 -397 399 -632 490 -204 79 -435 108 -663 84z"/>
<path d="M4615 7395 c-16 -8 -23 -14 -14 -15 8 0 24 7 35 15 23 18 18 18 -21
0z"/>
<path d="M6507 7369 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4560 7360 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M6227 7349 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4505 7324 c-19 -15 -22 -21 -9 -15 18 9 52 41 43 41 -2 0 -17 -12
-34 -26z"/>
<path d="M6520 7342 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M6276 7315 c10 -8 23 -15 28 -15 6 0 1 7 -10 15 -10 8 -23 15 -28 15
-6 0 -1 -7 10 -15z"/>
<path d="M6560 7322 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
7 -15 8 -15 2z"/>
<path d="M4190 7301 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M4146 7265 c-9 -14 -13 -25 -8 -25 5 0 14 11 20 25 6 14 10 25 8 25
-2 0 -11 -11 -20 -25z"/>
<path d="M6360 7251 c19 -16 37 -27 40 -25 5 6 -52 53 -65 54 -5 0 6 -13 25
-29z"/>
<path d="M4410 7255 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M6656 7225 c9 -14 21 -25 27 -25 6 0 0 11 -13 25 -13 14 -25 25 -27
25 -2 0 4 -11 13 -25z"/>
<path d="M4365 7229 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M4105 7220 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6442 7184 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M4056 7165 c-9 -14 -13 -25 -9 -25 10 0 36 40 30 46 -2 3 -12 -7 -21
-21z"/>
<path d="M4325 7179 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M6680 7182 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M6720 7164 c1 -13 29 -48 34 -43 2 2 -4 15 -15 28 -10 13 -19 19 -19
15z"/>
<path d="M4287 7153 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M6500 7145 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z"/>
<path d="M4255 7110 c-4 -6 -11 -8 -16 -5 -5 3 -6 -1 -2 -10 4 -12 8 -13 13
-5 4 7 14 16 21 21 9 6 10 9 2 9 -6 0 -15 -5 -18 -10z"/>
<path d="M6522 7104 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M6760 7101 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3963 7048 c-15 -24 -32 -46 -38 -50 -6 -5 -6 -8 2 -8 6 0 22 17 34
38 13 20 27 43 32 50 4 6 6 12 3 12 -3 0 -18 -19 -33 -42z"/>
<path d="M4207 7073 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5388 7063 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M6800 7061 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M5248 7053 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5533 7053 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5193 7043 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4175 7020 c-10 -11 -16 -22 -14 -24 2 -2 12 7 22 20 22 29 17 32 -8
4z"/>
<path d="M6607 7007 c15 -19 30 -33 32 -31 6 5 -40 64 -51 64 -5 0 3 -15 19
-33z"/>
<path d="M5710 7020 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M5078 7013 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6840 7002 c6 -13 14 -21 18 -18 3 4 -2 14 -12 24 -18 16 -18 16 -6
-6z"/>
<path d="M5010 6993 c-19 -8 -30 -17 -24 -20 7 -2 27 5 45 16 40 25 29 27 -21
4z"/>
<path d="M5770 7000 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M4125 6989 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M5070 6990 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3910 6970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5030 6970 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M5698 6973 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M5848 6968 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M4945 6960 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M6662 6935 c16 -19 24 -22 35 -14 7 6 13 16 13 22 0 7 -4 7 -12 -1
-17 -17 -31 -15 -24 3 4 10 -1 15 -14 15 -19 0 -19 -1 2 -25z"/>
<path d="M4900 6940 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z"/>
<path d="M5208 6943 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5528 6943 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5882 6941 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z"/>
<path d="M4103 6928 c-6 -7 -16 -24 -22 -38 -12 -24 -12 -24 4 -5 20 24 37 55
32 55 -3 0 -9 -6 -14 -12z"/>
<path d="M5378 6933 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5920 6931 c0 -4 12 -14 28 -21 25 -12 26 -13 7 -21 -13 -5 -15 -8
-5 -8 8 0 21 3 28 8 10 6 4 14 -22 29 -20 12 -36 18 -36 13z"/>
<path d="M3870 6910 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20 -3
0 -10 -9 -16 -20z"/>
<path d="M5552 6915 c-11 -12 -10 -15 2 -15 8 0 23 -3 33 -6 17 -5 17 -5 0 6
-9 6 -15 15 -12 21 10 14 -9 10 -23 -6z"/>
<path d="M6890 6915 c0 -8 4 -15 9 -15 5 0 16 -16 26 -36 9 -20 19 -33 22 -30
6 5 -44 96 -52 96 -3 0 -5 -7 -5 -15z"/>
<path d="M4845 6910 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5266 6911 c-17 -7 212 -4 244 3 8 2 -39 4 -105 4 -66 0 -128 -3
-139 -7z"/>
<path d="M4878 6888 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M5208 6893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5668 6873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5777 6869 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5054 6854 c-18 -14 -18 -15 6 -8 31 8 50 24 29 24 -8 0 -24 -7 -35
-16z"/>
<path d="M5720 6860 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M6760 6861 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M4047 6853 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M4759 6843 c-13 -17 -13 -17 6 -3 11 8 22 16 24 17 2 2 0 3 -6 3 -6
0 -17 -8 -24 -17z"/>
<path d="M6025 6850 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M6730 6846 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"/>
<path d="M3823 6824 c-6 -13 -7 -24 -3 -24 9 0 25 39 18 45 -2 2 -8 -7 -15
-21z"/>
<path d="M4006 6835 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M5784 6836 c11 -9 24 -16 30 -16 12 0 7 5 -24 19 -24 11 -24 11 -6
-3z"/>
<path d="M4985 6830 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M6070 6825 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z"/>
<path d="M4026 6803 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4710 6805 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z"/>
<path d="M6025 6810 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M6750 6807 c0 -4 11 -25 25 -45 14 -20 25 -30 25 -22 -1 15 -50 82
-50 67z"/>
<path d="M4916 6785 c-16 -12 -17 -15 -4 -15 8 0 21 7 28 15 16 19 1 19 -24 0z"/>
<path d="M5861 6797 c34 -28 99 -65 99 -57 0 6 -8 14 -17 17 -10 4 -29 15 -42
25 -13 10 -28 18 -34 18 -6 0 -8 -2 -6 -3z"/>
<path d="M6110 6782 c0 -6 9 -16 20 -22 11 -6 20 -18 20 -27 0 -9 3 -13 6 -10
4 3 16 1 28 -5 18 -9 17 -7 -4 13 -48 46 -70 62 -70 51z"/>
<path d="M6971 6775 c0 -20 25 -62 33 -54 2 2 -4 20 -14 39 -14 26 -19 30 -19
15z"/>
<path d="M3792 6756 c-17 -37 -15 -45 4 -18 8 12 14 27 12 33 -2 6 -9 -1 -16
-15z"/>
<path d="M4631 6726 c-28 -30 -51 -60 -51 -67 0 -6 11 0 25 15 14 15 25 29 25
32 0 3 16 21 35 40 20 19 31 34 27 34 -5 0 -32 -24 -61 -54z"/>
<path d="M5245 6735 c-278 -60 -507 -293 -564 -573 -18 -88 -14 -249 9 -338
30 -118 95 -236 176 -320 l22 -23 19 22 c11 12 34 46 52 74 l33 53 -30 37
c-145 184 -153 477 -19 660 123 165 272 243 467 243 110 -1 158 -12 247 -57
171 -86 290 -257 309 -444 14 -140 -35 -311 -116 -402 l-32 -37 48 -75 c27
-41 52 -75 56 -75 10 0 82 89 120 150 75 118 114 274 104 420 -7 103 -15 145
-49 230 -66 171 -203 318 -374 404 -121 60 -332 83 -478 51z"/>
<path d="M5970 6724 c0 -9 58 -64 67 -64 4 1 -2 9 -15 20 -13 11 -21 24 -18
29 3 5 -1 12 -10 15 -20 7 -24 8 -24 0z"/>
<path d="M3956 6685 c-10 -19 -21 -33 -27 -30 -5 4 -9 -1 -9 -10 0 -27 36 0
49 36 15 45 7 47 -13 4z"/>
<path d="M6059 6711 c12 -8 12 -12 2 -24 -7 -10 -8 -18 -2 -21 11 -7 25 29 16
44 -3 5 -12 10 -18 10 -8 0 -7 -4 2 -9z"/>
<path d="M6810 6696 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"/>
<path d="M6200 6690 c0 -5 10 -15 23 -21 22 -12 22 -12 3 10 -21 23 -26 26
-26 11z"/>
<path d="M7010 6684 c0 -10 5 -26 10 -34 8 -11 9 -7 4 15 -8 37 -14 45 -14 19z"/>
<path d="M4770 6665 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M6980 6650 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3742 6630 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4735 6640 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M6252 6630 c36 -61 43 -70 51 -70 11 0 -12 40 -41 69 l-25 26 15 -25z"/>
<path d="M6836 6642 c-2 -4 1 -18 9 -31 12 -24 12 -24 11 8 -1 30 -9 40 -20
23z"/>
<path d="M6066 6627 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M6130 6632 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
7 -15 8 -15 2z"/>
<path d="M7044 6590 c4 -19 11 -44 16 -55 4 -11 11 -29 14 -39 4 -10 9 -16 12
-13 6 5 -33 126 -43 137 -3 3 -2 -11 1 -30z"/>
<path d="M4525 6599 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M4650 6599 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6123 6561 c18 -27 36 -47 41 -44 4 3 4 8 -1 12 -5 3 -19 23 -32 44
-12 20 -26 37 -31 37 -5 0 6 -22 23 -49z"/>
<path d="M4690 6579 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3716 6565 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M4537 6573 c-3 -5 -1 -17 4 -28 7 -17 8 -17 8 8 1 27 -4 34 -12 20z"/>
<path d="M6250 6570 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4495 6551 c-6 -10 -18 -33 -28 -50 -14 -24 -15 -35 -7 -49 11 -16
11 -16 6 1 -6 19 8 55 29 72 15 12 27 45 17 45 -4 0 -12 -8 -17 -19z"/>
<path d="M4669 6543 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M6320 6536 c0 -2 13 -30 30 -62 16 -32 30 -53 30 -46 0 14 -48 112
-55 112 -3 0 -5 -2 -5 -4z"/>
<path d="M3720 6519 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3862 6510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3891 6513 c0 -12 -4 -30 -7 -40 -5 -17 -5 -17 6 0 6 10 9 28 7 40
-4 22 -4 22 -6 0z"/>
<path d="M4580 6469 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4605 6450 c-9 -16 -13 -30 -11 -30 3 0 12 14 21 30 9 17 13 30 11
30 -3 0 -12 -13 -21 -30z"/>
<path d="M6185 6470 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7041 6464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5398 6463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5290 6451 c-14 -4 -20 -9 -15 -13 6 -3 24 1 40 8 32 14 17 17 -25 5z"/>
<path d="M5483 6445 c15 -9 31 -14 35 -11 9 5 77 -16 88 -27 4 -4 14 -7 23 -7
22 1 -63 40 -97 45 -15 2 -38 7 -52 10 -24 6 -24 6 3 -10z"/>
<path d="M6231 6444 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6331 6450 c0 -8 6 -24 14 -34 19 -25 19 -9 0 24 -8 14 -14 19 -14
10z"/>
<path d="M6902 6440 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6217 6415 c9 -19 18 -33 21 -31 4 5 -26 66 -33 66 -3 0 3 -16 12
-35z"/>
<path d="M7091 6428 c-1 -16 4 -28 9 -28 12 0 12 8 0 35 -8 18 -9 17 -9 -7z"/>
<path d="M4427 6421 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
<path d="M5235 6430 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M5440 6425 c0 -8 8 -15 18 -14 10 0 12 3 4 6 -6 2 -10 9 -6 14 3 5 0
9 -5 9 -6 0 -11 -7 -11 -15z"/>
<path d="M5185 6411 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z"/>
<path d="M5298 6413 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5356 6412 c-3 -5 8 -6 25 -4 16 2 29 6 29 8 0 8 -49 4 -54 -4z"/>
<path d="M3665 6360 c-4 -28 -5 -54 -2 -56 3 -3 8 18 12 46 4 28 5 54 2 56 -3
3 -8 -18 -12 -46z"/>
<path d="M6951 6394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3861 6384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4562 6370 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M6916 6378 c4 -13 11 -41 15 -63 4 -25 7 -31 8 -15 1 40 -11 100 -21
100 -5 0 -6 -10 -2 -22z"/>
<path d="M4413 6370 c-11 -16 -10 -18 2 -14 8 4 15 12 15 20 0 19 -3 18 -17
-6z"/>
<path d="M5130 6370 l-25 -21 28 12 c15 7 27 16 27 21 0 11 -1 10 -30 -12z"/>
<path d="M5650 6381 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M5686 6355 c4 -9 16 -21 28 -27 19 -10 19 -9 -3 16 -28 30 -33 32
-25 11z"/>
<path d="M6260 6360 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6401 6360 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z"/>
<path d="M3846 6314 c-3 -26 -4 -49 -2 -52 3 -2 8 17 11 43 4 25 5 48 3 51 -3
2 -8 -17 -12 -42z"/>
<path d="M5085 6330 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6265 6300 c9 -31 25 -50 25 -29 0 7 -8 23 -16 34 -16 19 -16 19 -9
-5z"/>
<path d="M5055 6299 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M5220 6299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5318 6303 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5368 6303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5418 6303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5468 6303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6421 6294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5223 6258 c-50 -43 -99 -105 -89 -115 2 -3 11 9 20 25 18 34 102
112 122 112 7 0 16 5 19 10 15 25 -26 6 -72 -32z"/>
<path d="M4382 6270 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4531 6275 c-1 -11 -4 -31 -6 -45 l-6 -25 11 25 c6 13 9 33 7 45 -4
19 -4 19 -6 0z"/>
<path d="M5097 6283 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5720 6280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5760 6285 c0 -5 10 -21 21 -35 13 -17 17 -18 10 -5 -17 33 -31 51
-31 40z"/>
<path d="M7122 6265 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5557 6269 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5036 6257 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4496 6245 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M6436 6230 c8 -43 14 -51 14 -19 0 13 -5 31 -10 39 -7 11 -8 5 -4
-20z"/>
<path d="M5617 6220 c15 -16 29 -30 31 -30 8 0 -32 49 -44 54 -9 3 -3 -7 13
-24z"/>
<path d="M4372 6220 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5136 6224 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z"/>
<path d="M6286 6220 c4 -14 11 -43 15 -65 7 -34 8 -32 4 14 -3 30 -10 59 -16
65 -8 8 -9 5 -3 -14z"/>
<path d="M6941 6204 c0 -21 4 -48 9 -59 5 -12 6 1 3 35 -7 66 -13 79 -12 24z"/>
<path d="M7128 6223 c-2 -5 0 -39 3 -78 l5 -70 2 78 c2 70 -1 90 -10 70z"/>
<path d="M4990 6195 c-7 -14 -10 -28 -7 -31 4 -4 11 6 17 21 14 36 7 42 -10
10z"/>
<path d="M5800 6213 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M4508 6175 c-6 -30 -3 -43 8 -33 3 4 4 19 2 35 l-3 28 -7 -30z"/>
<path d="M5770 6190 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3644 6105 c0 -49 1 -71 3 -48 2 23 2 64 0 90 -2 26 -3 8 -3 -42z"/>
<path d="M3661 6174 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4356 6139 c-4 -28 -5 -53 -3 -56 3 -2 8 19 12 47 4 28 5 53 2 56 -2
3 -7 -19 -11 -47z"/>
<path d="M5015 6170 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5661 6175 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M6431 6134 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6451 6124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4980 6114 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M4381 6094 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6411 6094 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4504 6010 c0 -52 1 -74 3 -47 2 26 2 68 0 95 -2 26 -3 4 -3 -48z"/>
<path d="M6952 6075 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3662 6060 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5698 6043 c6 -65 10 -70 11 -15 0 28 -3 52 -7 52 -4 0 -6 -17 -4
-37z"/>
<path d="M6452 6060 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5721 6044 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5853 6005 c0 -33 2 -45 4 -27 2 18 2 45 0 60 -2 15 -4 0 -4 -33z"/>
<path d="M4353 5995 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M7142 6020 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6311 6014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6421 6014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4952 5970 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5722 5980 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6452 5975 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3642 5965 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5741 5934 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7127 5836 c-4 -54 -6 -101 -3 -103 6 -6 19 160 14 183 -2 10 -7 -25
-11 -80z"/>
<path d="M3642 5890 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M4352 5900 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4383 5880 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M6312 5900 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6432 5900 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4970 5883 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M5131 5874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5671 5874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4500 5873 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M6943 5840 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M6441 5824 c-18 -69 -31 -129 -30 -144 1 -20 16 31 34 110 15 71 12
95 -4 34z"/>
<path d="M6292 5825 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3648 5810 c2 -16 6 -30 8 -30 2 0 4 14 4 30 0 17 -4 30 -8 30 -5 0
-7 -13 -4 -30z"/>
<path d="M3671 5824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4361 5828 c0 -7 4 -22 9 -33 8 -18 9 -17 9 7 1 15 -3 30 -9 33 -5 3
-10 0 -9 -7z"/>
<path d="M4491 5824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4990 5836 c0 -4 12 -30 27 -57 21 -39 29 -47 40 -38 7 6 13 16 13
21 0 6 -7 5 -15 -2 -12 -10 -16 -9 -22 6 -10 27 -43 81 -43 70z"/>
<path d="M5700 5835 c0 -11 24 -55 30 -55 4 0 12 -12 19 -26 11 -26 12 -26 26
-8 19 25 18 31 0 31 -9 0 -14 -6 -11 -14 11 -27 -4 -11 -30 32 -23 41 -34 53
-34 40z"/>
<path d="M5801 5819 c-7 -12 -10 -23 -8 -26 3 -3 11 5 17 17 15 28 8 35 -9 9z"/>
<path d="M6411 5824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4526 5785 c5 -42 34 -110 34 -79 0 6 -3 14 -7 18 -5 4 -13 31 -20
59 l-12 52 5 -50z"/>
<path d="M5080 5805 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"/>
<path d="M3662 5750 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6932 5750 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4381 5744 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6270 5749 c0 -2 -4 -19 -9 -37 -5 -17 -6 -32 -1 -32 10 0 24 59 16
67 -3 4 -6 4 -6 2z"/>
<path d="M3856 5690 c3 -30 7 -57 10 -59 10 -11 10 8 -2 59 l-13 55 5 -55z"/>
<path d="M6916 5685 c-3 -9 -6 -24 -5 -33 0 -9 5 -4 10 12 9 32 6 48 -5 21z"/>
<path d="M4561 5664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7052 5650 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M4400 5661 c0 -5 5 -13 10 -16 6 -3 10 -15 10 -25 0 -10 4 -21 9 -24
13 -8 -1 47 -16 62 -7 7 -13 9 -13 3z"/>
<path d="M6941 5634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4572 5633 c2 -5 11 -24 20 -43 9 -20 27 -44 40 -55 23 -20 23 -20 7
0 -9 11 -26 39 -38 63 -12 23 -24 42 -27 42 -3 0 -4 -3 -2 -7z"/>
<path d="M6220 5608 c-8 -18 -22 -45 -32 -60 -11 -16 -15 -28 -9 -28 10 0 65
109 58 116 -2 3 -10 -10 -17 -28z"/>
<path d="M7082 5593 c-5 -21 -13 -53 -17 -70 -4 -18 -11 -35 -16 -38 -5 -4 -8
-21 -8 -38 1 -20 3 -25 6 -12 2 11 10 38 18 60 27 83 38 125 33 130 -3 3 -10
-12 -16 -32z"/>
<path d="M3845 5599 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M3875 5601 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M6365 5579 c-10 -30 0 -33 13 -4 6 14 7 25 2 25 -5 0 -12 -9 -15 -21z"/>
<path d="M7052 5580 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3702 5555 c-1 -16 5 -43 14 -60 12 -25 13 -26 8 -5 -3 14 -9 41 -14
60 -8 35 -8 35 -8 5z"/>
<path d="M4440 5571 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M3890 5551 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z"/>
<path d="M6864 5498 c-6 -18 -21 -58 -33 -88 -12 -30 -18 -50 -13 -45 16 16
63 136 60 152 -2 8 -8 -1 -14 -19z"/>
<path d="M6330 5499 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"/>
<path d="M4651 5505 c0 -5 11 -23 24 -40 14 -16 25 -25 25 -20 0 6 -11 24 -25
40 -13 17 -24 26 -24 20z"/>
<path d="M4490 5492 c0 -7 16 -36 35 -65 19 -28 35 -47 35 -41 0 6 -8 22 -19
35 -10 13 -25 38 -34 54 -9 17 -16 24 -17 17z"/>
<path d="M3924 5429 c0 -36 2 -53 6 -39 l6 25 14 -25 c9 -17 10 -25 1 -28 -6
-2 -11 -8 -11 -14 0 -6 6 -6 15 2 12 10 15 10 15 0 0 -8 3 -11 6 -8 3 4 -2 21
-11 39 -10 18 -22 50 -29 72 l-11 40 -1 -64z"/>
<path d="M6126 5465 c-17 -25 -16 -25 4 -13 11 7 20 18 20 25 0 19 -6 16 -24
-12z"/>
<path d="M3743 5410 c6 -16 13 -28 15 -26 6 6 -10 56 -18 56 -5 0 -3 -13 3
-30z"/>
<path d="M6272 5414 c-7 -14 -8 -23 -2 -19 15 9 32 45 22 45 -5 0 -14 -12 -20
-26z"/>
<path d="M6998 5433 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6095 5420 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4728 5404 c13 -24 40 -49 47 -42 3 2 -9 18 -26 34 -24 22 -29 24
-21 8z"/>
<path d="M6057 5385 c-11 -16 -13 -26 -6 -30 6 -4 13 -4 16 -1 4 3 0 6 -7 6
-9 0 -8 6 5 20 10 11 16 22 14 24 -2 2 -12 -6 -22 -19z"/>
<path d="M6121 5388 c-14 -23 -14 -23 8 -4 22 20 25 26 13 26 -4 0 -13 -10
-21 -22z"/>
<path d="M7017 5404 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M6020 5354 c1 -12 39 -61 40 -51 0 5 -9 20 -20 34 -11 14 -20 22 -20
17z"/>
<path d="M3921 5334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4590 5343 c0 -16 28 -36 40 -28 5 3 10 12 10 18 0 7 -4 6 -10 -3 -9
-13 -12 -12 -25 4 -8 11 -15 15 -15 9z"/>
<path d="M3786 5315 c3 -16 11 -37 18 -45 8 -8 18 -28 25 -45 l11 -30 -6 30
c-8 43 -8 57 0 80 5 15 4 14 -5 -2 -16 -31 -24 -29 -37 10 -11 31 -12 32 -6 2z"/>
<path d="M3805 5330 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4760 5325 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M6188 5319 c-10 -10 -18 -23 -18 -28 0 -5 -16 -19 -35 -32 -34 -23
-44 -39 -26 -39 9 0 111 105 111 114 0 11 -15 4 -32 -15z"/>
<path d="M6786 5315 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M6977 5291 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
<path d="M4727 5278 c-10 -10 -15 -23 -11 -29 3 -6 12 2 20 17 17 33 15 37 -9
12z"/>
<path d="M6071 5280 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z"/>
<path d="M4010 5278 c0 -4 5 -19 12 -33 9 -21 9 -28 -2 -35 -9 -5 -5 -8 12 -7
24 2 25 3 12 27 -19 36 -34 56 -34 48z"/>
<path d="M4660 5265 c26 -34 41 -48 47 -42 6 6 -8 21 -42 47 -19 14 -19 14 -5
-5z"/>
<path d="M6747 5253 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M6715 5200 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M6883 5185 c-3 -9 -3 -19 1 -22 3 -4 6 1 6 10 0 8 6 18 13 20 9 4 9
6 -1 6 -7 1 -15 -6 -19 -14z"/>
<path d="M3857 5172 c3 -12 9 -25 14 -28 14 -8 11 10 -6 32 -13 18 -14 18 -8
-4z"/>
<path d="M6687 5154 c-9 -18 -29 -46 -44 -65 l-28 -33 28 18 c15 10 27 23 27
29 0 6 9 21 21 33 12 12 19 29 17 36 -3 8 -11 1 -21 -18z"/>
<path d="M6897 5145 c-23 -48 -21 -57 4 -18 11 18 19 38 17 44 -2 6 -11 -6
-21 -26z"/>
<path d="M4080 5164 c1 -13 29 -48 34 -43 2 2 -4 15 -15 28 -10 13 -19 19 -19
15z"/>
<path d="M3889 5115 c17 -34 31 -51 31 -39 0 7 -9 22 -21 35 -16 19 -18 20
-10 4z"/>
<path d="M6704 5113 c-3 -10 -10 -27 -16 -38 -10 -17 -9 -18 4 -7 9 7 18 24
21 37 5 29 -1 34 -9 8z"/>
<path d="M4120 5092 c6 -13 14 -21 18 -18 3 4 -2 14 -12 24 -18 16 -18 16 -6
-6z"/>
<path d="M6844 5064 c-4 -10 -16 -30 -26 -43 -10 -13 -18 -29 -18 -35 0 -6 14
11 31 37 18 26 29 50 26 54 -4 3 -10 -3 -13 -13z"/>
<path d="M4100 5050 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3945 5030 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4190 5012 c0 -10 26 -34 50 -47 8 -4 2 6 -14 23 -32 34 -36 37 -36
24z"/>
<path d="M6580 5009 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3970 5000 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4170 4980 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6535 4968 l-20 -21 23 12 c12 6 22 16 22 21 0 13 -2 12 -25 -12z"/>
<path d="M6750 4938 c-30 -46 -33 -51 -22 -44 17 10 55 66 45 66 -4 0 -15 -10
-23 -22z"/>
<path d="M4020 4944 c0 -17 37 -60 45 -52 4 4 -5 19 -19 35 -14 15 -26 23 -26
17z"/>
<path d="M4250 4947 c0 -10 41 -49 46 -45 2 3 -7 15 -21 28 -14 13 -25 20 -25
17z"/>
<path d="M6477 4910 c-16 -17 -25 -30 -20 -30 4 0 21 14 37 30 16 17 25 30 20
30 -5 -1 -22 -14 -37 -30z"/>
<path d="M6521 4914 c-7 -8 -11 -17 -9 -20 3 -2 13 5 23 16 10 11 14 20 9 20
-5 -1 -15 -8 -23 -16z"/>
<path d="M4241 4904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4316 4885 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"/>
<path d="M6430 4855 c-7 -8 -8 -15 -2 -15 5 0 15 7 22 15 7 8 8 15 2 15 -5 0
-15 -7 -22 -15z"/>
<path d="M6685 4860 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4350 4845 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z"/>
<path d="M4105 4838 c15 -25 48 -58 59 -58 6 0 3 5 -6 11 -10 5 -18 15 -18 22
0 7 -9 18 -21 25 -17 11 -20 11 -14 0z"/>
<path d="M6655 4830 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M6384 4819 c-3 -6 -2 -15 3 -20 5 -5 9 -1 9 11 0 23 -2 24 -12 9z"/>
<path d="M4403 4803 c4 -3 0 -17 -8 -30 -9 -13 -12 -23 -7 -23 5 0 14 11 20
25 15 32 15 35 0 35 -6 0 -8 -3 -5 -7z"/>
<path d="M6450 4790 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4215 4770 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M6575 4780 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4348 4755 c-6 -30 -3 -43 8 -33 3 4 4 19 2 35 l-3 28 -7 -30z"/>
<path d="M4180 4762 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M4322 4750 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6420 4764 c0 -15 56 -104 66 -104 13 0 44 33 44 46 0 5 -4 5 -8 -1
-4 -5 -15 -16 -24 -23 -15 -12 -19 -8 -40 37 -20 44 -38 65 -38 45z"/>
<path d="M4260 4693 c0 -9 54 -53 65 -53 7 0 -22 29 -47 49 -10 7 -18 9 -18 4z"/>
<path d="M4310 4696 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M5380 4006 c-133 -38 -180 -103 -181 -250 -1 -78 26 -136 79 -171 53
-35 154 -70 245 -85 43 -7 88 -21 100 -30 19 -16 20 -21 9 -45 l-13 -28 -102
5 c-79 4 -118 12 -168 32 -36 14 -68 26 -71 26 -7 0 -73 -117 -82 -147 -6 -18
0 -26 36 -47 81 -47 182 -70 308 -70 156 -1 211 14 270 73 53 54 70 100 70
195 0 141 -73 200 -305 246 -120 24 -138 33 -133 64 7 52 117 56 253 11 64
-21 71 -22 80 -7 5 10 27 47 48 84 l37 67 -22 15 c-13 8 -59 27 -103 42 -65
22 -102 28 -195 30 -74 2 -131 -1 -160 -10z"/>
<path d="M1990 3948 c-157 -343 -300 -683 -300 -713 l0 -35 116 0 115 0 26 60
26 60 148 0 149 0 30 -60 30 -60 125 0 c77 0 125 4 125 10 0 5 -19 52 -41 102
-23 51 -57 129 -76 173 -31 71 -95 213 -198 445 l-34 75 -106 3 -106 3 -29
-63z m180 -357 l29 -81 -74 0 c-41 0 -75 2 -75 4 0 3 26 74 60 161 14 36 17
32 60 -84z"/>
<path d="M2620 3605 l0 -405 120 0 120 0 0 198 1 197 22 -28 c12 -15 56 -78
97 -141 41 -63 80 -113 85 -112 6 1 28 29 50 62 92 136 149 214 157 214 4 0 8
-88 8 -195 l0 -195 120 0 120 0 0 405 0 405 -119 0 -119 0 -52 -82 c-29 -46
-76 -120 -104 -165 -52 -83 -69 -97 -80 -66 -4 9 -48 84 -98 165 l-91 148
-118 0 -119 0 0 -405z"/>
<path d="M3652 3608 l3 -403 313 -3 312 -2 0 100 0 100 -190 0 -190 0 0 305 0
305 -125 0 -125 0 2 -402z"/>
<path d="M4626 3978 c-24 -57 -137 -311 -156 -353 -51 -108 -180 -408 -180
-416 0 -6 51 -8 127 -7 l128 3 27 58 28 57 148 0 149 0 26 -60 26 -60 130 0
c120 0 130 1 124 18 -4 9 -65 148 -136 307 -70 160 -147 334 -170 387 l-42 98
-107 0 -108 0 -14 -32z m163 -373 c17 -44 31 -83 31 -87 0 -5 -34 -8 -75 -8
l-74 0 28 78 c43 114 43 114 51 105 5 -4 22 -44 39 -88z"/>
<path d="M6270 3605 l0 -405 169 0 c351 0 463 40 544 195 32 59 32 62 32 205
0 129 -3 151 -24 203 -28 71 -66 117 -127 152 -73 43 -156 55 -386 55 l-208 0
0 -405z m415 195 c68 -24 109 -144 87 -255 -21 -104 -62 -136 -184 -143 l-78
-5 0 205 c0 113 3 208 8 212 9 10 128 0 167 -14z"/>
<path d="M7271 3818 c-47 -106 -97 -220 -112 -253 -15 -33 -45 -100 -67 -150
-22 -49 -51 -116 -66 -149 -14 -32 -26 -60 -26 -61 0 -2 59 -4 130 -4 l130 -2
26 61 27 60 148 0 148 0 28 -57 29 -58 127 -3 c84 -2 127 1 127 8 0 6 -14 41
-31 78 -27 58 -186 416 -285 642 l-33 75 -108 3 -108 2 -84 -192z m245 -245
l24 -63 -75 0 c-41 0 -75 4 -75 8 0 8 39 111 62 162 l11 25 15 -35 c8 -19 25
-63 38 -97z"/>
<path d="M7802 3913 c2 -69 7 -98 16 -99 6 -1 53 -2 102 -3 l90 -1 0 -305 0
-306 123 3 122 3 0 303 c0 194 4 302 10 303 6 1 44 2 85 3 128 3 115 -8 112
101 l-3 95 -330 0 -330 0 3 -97z"/>
<path d="M8711 3988 c-9 -23 -192 -438 -230 -519 -50 -109 -111 -252 -111
-261 0 -5 56 -7 128 -6 l127 3 27 58 28 57 149 0 148 0 26 -60 26 -60 130 0
131 0 -9 23 c-16 40 -68 161 -96 222 -15 33 -35 78 -45 100 -10 22 -46 103
-80 180 -34 77 -77 173 -95 213 l-34 72 -106 0 c-100 0 -106 -1 -114 -22z
m159 -383 c17 -48 30 -88 27 -91 -2 -3 -36 -4 -75 -2 l-70 3 36 92 c19 51 38
90 42 88 4 -2 22 -43 40 -90z"/>
<path d="M1490 3028 c-11 -12 -20 -31 -20 -42 -1 -12 -1 -115 0 -231 l0 -210
26 -22 25 -23 3879 0 3879 0 26 23 25 22 1 215 c0 118 0 222 -1 231 0 8 -9 25
-20 37 l-21 22 -3889 0 -3889 0 -21 -22z m280 -107 c0 -48 -8 -71 -25 -71 -14
0 -25 31 -25 71 0 30 7 39 32 39 14 0 18 -8 18 -39z m284 33 c11 -4 16 -19 16
-45 0 -32 -3 -39 -20 -39 -13 0 -20 7 -20 20 0 11 -7 23 -15 26 -22 9 -62 -4
-69 -22 -10 -26 11 -50 68 -78 68 -33 86 -72 62 -130 -17 -40 -47 -56 -104
-56 -72 0 -82 8 -82 56 0 37 3 44 20 44 15 0 20 -7 20 -25 0 -18 7 -26 26 -31
37 -9 74 10 74 40 0 25 -41 66 -66 66 -7 0 -26 12 -43 26 -22 18 -31 35 -31
56 0 35 27 85 49 91 25 7 98 8 115 1z m184 -15 c2 -10 -5 -19 -18 -22 -18 -5
-20 -13 -20 -79 0 -40 5 -90 10 -110 17 -61 72 -77 115 -33 23 22 25 31 25
125 0 84 -2 100 -16 100 -9 0 -14 7 -12 18 3 13 14 17 53 17 39 0 50 -4 53
-17 2 -11 -3 -18 -12 -18 -14 0 -16 -15 -16 -97 0 -146 -31 -193 -127 -193
-100 0 -123 35 -123 190 0 82 -3 100 -15 100 -15 0 -20 21 -8 34 4 4 30 6 58
4 36 -2 51 -7 53 -19z m403 -5 c33 -16 44 -49 35 -98 -8 -40 -42 -66 -97 -73
-39 -6 -39 -6 -39 -49 0 -37 3 -44 20 -44 11 0 20 -7 20 -15 0 -12 -14 -15
-65 -15 -51 0 -65 3 -65 15 0 8 7 15 15 15 13 0 15 21 15 125 0 104 -2 125
-15 125 -8 0 -15 6 -15 14 0 29 137 28 191 0z m231 16 c10 0 30 -11 43 -25 27
-26 34 -80 14 -117 -12 -23 -66 -48 -104 -48 -23 0 -25 -4 -25 -45 0 -38 3
-45 20 -45 11 0 20 -7 20 -15 0 -12 -14 -15 -65 -15 -51 0 -65 3 -65 15 0 8 7
15 15 15 13 0 15 21 15 125 0 104 -2 125 -15 125 -8 0 -15 6 -15 14 0 16 61
26 114 20 16 -2 37 -4 48 -4z m313 -2 c103 -47 89 -266 -19 -308 -68 -25 -143
-3 -173 53 -25 46 -24 162 2 201 39 59 125 84 190 54z m313 -21 c32 -29 31
-92 -3 -127 l-26 -27 28 -51 c17 -33 34 -52 45 -52 10 0 18 -7 18 -15 0 -11
-11 -15 -42 -15 -42 0 -44 2 -68 52 -44 91 -70 99 -70 22 0 -37 3 -44 20 -44
11 0 20 -7 20 -15 0 -12 -14 -15 -65 -15 -66 0 -81 10 -45 30 19 10 21 20 19
126 -1 101 -3 116 -20 125 -31 16 -22 28 24 31 76 6 141 -3 165 -25z m322 -12
c0 -38 -3 -45 -19 -45 -13 0 -21 8 -23 23 -2 16 -11 23 -30 25 l-28 3 0 -125
c0 -119 1 -126 20 -126 11 0 20 -7 20 -15 0 -12 -14 -15 -70 -15 -56 0 -70 3
-70 15 0 8 9 15 20 15 19 0 20 7 20 125 l0 125 -30 0 c-25 0 -30 -4 -30 -25 0
-18 -5 -25 -20 -25 -17 0 -20 7 -20 38 0 21 3 42 7 45 3 4 62 7 130 7 l123 0
0 -45z m140 26 c0 -10 -7 -21 -15 -25 -12 -4 -15 -27 -15 -121 0 -94 3 -117
15 -121 8 -4 15 -12 15 -20 0 -10 -15 -14 -55 -14 -42 0 -55 3 -55 15 0 8 7
15 15 15 13 0 15 21 15 125 0 104 -2 125 -15 125 -8 0 -15 8 -15 18 0 16 9 19
55 21 48 1 55 -1 55 -18z m196 -96 c41 -63 77 -115 79 -115 3 0 5 43 5 95 0
80 -3 95 -16 95 -9 0 -14 7 -12 18 5 24 101 25 106 1 2 -10 -5 -19 -18 -22
-19 -5 -20 -12 -20 -141 l0 -136 -25 0 c-21 0 -37 19 -97 110 -40 60 -77 110
-81 110 -4 0 -6 -42 -5 -92 3 -88 4 -93 26 -96 12 -2 22 -10 22 -18 0 -11 -15
-14 -60 -14 -47 0 -60 3 -60 15 0 8 7 15 15 15 13 0 15 21 15 125 0 104 -2
125 -15 125 -15 0 -21 21 -8 33 3 4 21 7 40 7 32 0 38 -6 109 -115z m401 103
c29 -11 33 -16 33 -50 0 -31 -4 -38 -19 -38 -13 0 -21 8 -23 22 -7 51 -113 45
-137 -8 -16 -35 -14 -120 4 -153 18 -35 63 -55 104 -46 26 5 31 11 31 36 0 19
-5 29 -15 29 -8 0 -15 9 -15 20 0 18 7 20 55 20 48 0 55 -2 55 -20 0 -11 -7
-20 -15 -20 -11 0 -15 -11 -15 -43 0 -42 -1 -44 -39 -55 -131 -40 -220 22
-221 153 0 124 100 195 217 153z m417 2 c22 -8 26 -16 26 -50 0 -33 -3 -40
-20 -40 -14 0 -20 7 -20 24 0 39 -70 49 -113 16 -44 -35 -48 -154 -6 -199 38
-41 115 -40 121 1 2 13 11 24 21 26 14 3 17 -4 17 -41 0 -42 -2 -45 -31 -51
-95 -19 -168 7 -199 69 -22 44 -19 153 5 189 39 59 127 84 199 56z m286 -5
c44 -23 64 -69 64 -145 0 -105 -40 -157 -128 -167 -93 -11 -150 49 -150 157 0
68 26 125 68 151 38 23 106 25 146 4z m250 -112 c25 -68 47 -123 50 -123 3 0
25 51 50 113 24 61 48 118 53 125 14 18 84 16 91 -4 8 -21 8 -21 -14 -27 -19
-5 -20 -14 -20 -122 0 -109 1 -117 20 -122 39 -10 14 -28 -43 -31 -46 -2 -57
0 -57 13 0 8 7 15 15 15 12 0 15 17 14 93 0 59 -3 85 -9 72 -46 -109 -70 -172
-70 -183 0 -9 -11 -12 -31 -10 -30 3 -33 8 -75 113 l-43 110 0 -97 c-1 -92 0
-98 19 -98 11 0 20 -7 20 -15 0 -12 -13 -15 -55 -15 -40 0 -55 4 -55 14 0 8 7
16 15 20 12 4 15 27 15 121 0 94 -3 117 -15 121 -8 4 -15 14 -15 24 0 15 8 18
48 16 l47 -1 45 -122z m371 106 c6 -12 27 -65 47 -119 21 -53 41 -98 46 -99 5
-1 30 51 55 116 l47 118 44 0 c35 0 45 -4 48 -17 2 -11 -3 -18 -12 -18 -14 0
-16 -18 -16 -125 0 -104 2 -125 15 -125 8 0 15 -7 15 -15 0 -12 -13 -15 -60
-15 -47 0 -60 3 -60 15 0 8 6 15 14 15 8 0 17 8 21 19 8 25 4 162 -5 156 -3
-2 -24 -49 -46 -105 -35 -91 -41 -100 -65 -100 -23 0 -29 9 -64 100 -20 55
-40 100 -44 100 -3 0 -6 -37 -6 -82 0 -70 3 -83 18 -86 9 -2 17 -10 17 -18 0
-10 -14 -14 -55 -14 -42 0 -55 3 -55 15 0 8 7 15 15 15 13 0 15 21 15 125 0
104 -2 125 -15 125 -15 0 -21 21 -8 33 3 4 23 7 44 7 29 0 41 -5 50 -21z m423
6 c3 -9 -3 -19 -14 -25 -18 -10 -20 -22 -20 -106 0 -87 2 -97 25 -119 31 -32
69 -32 100 0 23 22 25 31 25 125 0 82 -3 100 -15 100 -8 0 -15 9 -15 21 0 18
5 20 52 17 41 -2 54 -7 56 -20 2 -11 -3 -18 -12 -18 -13 0 -16 -14 -16 -84 0
-105 -6 -136 -35 -172 -22 -27 -28 -29 -94 -29 -60 0 -74 3 -93 23 -21 20 -23
34 -26 138 -3 88 -7 117 -18 121 -8 3 -14 14 -14 24 0 16 8 19 54 19 38 0 55
-4 60 -15z m291 7 c6 -4 44 -58 85 -121 l75 -113 3 93 c3 84 1 95 -17 109 -32
24 -12 42 40 38 32 -3 45 -8 47 -21 2 -10 -3 -17 -12 -17 -14 0 -16 -19 -16
-140 l0 -140 -27 0 c-24 0 -37 14 -97 106 -38 57 -73 107 -78 110 -4 3 -8 -38
-8 -90 l0 -96 26 0 c50 0 19 -25 -36 -28 -59 -4 -84 10 -51 27 19 11 21 20 21
127 0 93 -3 116 -15 120 -8 4 -15 15 -15 25 0 15 7 19 33 19 17 0 37 -4 42 -8z
m375 -12 c0 -11 -7 -20 -15 -20 -13 0 -15 -21 -15 -125 0 -104 2 -125 15 -125
8 0 15 -7 15 -15 0 -12 -13 -15 -60 -15 -47 0 -60 3 -60 15 0 8 7 15 15 15 13
0 15 21 15 125 0 104 -2 125 -15 125 -15 0 -21 21 -8 33 3 4 30 7 60 7 46 0
53 -2 53 -20z m259 9 c28 -10 31 -15 31 -50 0 -32 -3 -39 -20 -39 -13 0 -20 7
-20 20 0 44 -83 55 -120 15 -28 -30 -38 -106 -20 -159 23 -69 130 -93 141 -31
7 36 34 23 37 -18 3 -32 0 -38 -23 -47 -56 -21 -148 -9 -181 25 -74 73 -49
248 41 285 29 12 97 12 134 -1z m283 -109 c22 -63 42 -125 45 -138 3 -13 16
-28 29 -32 13 -5 24 -14 24 -20 0 -16 -132 -8 -138 8 -2 6 3 12 12 12 19 0 22
40 4 58 -16 16 -93 15 -105 0 -22 -29 -23 -50 -3 -55 11 -3 20 -12 20 -19 0
-10 -16 -14 -60 -14 -44 0 -60 4 -60 14 0 7 8 16 18 18 13 4 31 44 66 147 l49
142 29 -3 c29 -3 32 -8 70 -118z m358 75 c0 -38 -3 -45 -20 -45 -15 0 -20 7
-20 25 0 21 -5 25 -30 25 l-30 0 0 -125 c0 -118 1 -125 20 -125 11 0 20 -7 20
-15 0 -12 -14 -15 -70 -15 -56 0 -70 3 -70 15 0 8 9 15 20 15 19 0 20 7 20
126 l0 125 -27 -3 c-16 -2 -27 -10 -29 -21 -3 -25 -10 -31 -29 -24 -15 6 -22
67 -8 80 3 4 62 7 130 7 l123 0 0 -45z m144 30 c3 -9 -3 -19 -14 -25 -18 -10
-20 -22 -20 -125 0 -104 2 -115 20 -125 36 -19 21 -30 -40 -30 -47 0 -60 3
-60 15 0 8 7 15 15 15 13 0 15 21 15 125 0 104 -2 125 -15 125 -8 0 -15 9 -15
20 0 18 7 20 54 20 38 0 55 -4 60 -15z m256 0 c43 -22 63 -68 63 -146 0 -109
-51 -169 -145 -169 -64 0 -102 28 -123 90 -29 89 -5 182 58 220 39 24 106 26
147 5z m255 -97 c39 -59 74 -110 78 -114 4 -4 7 36 7 89 0 82 -3 97 -16 97 -9
0 -14 7 -12 18 3 13 14 17 53 17 39 0 50 -4 53 -17 2 -11 -3 -18 -12 -18 -14
0 -16 -19 -16 -140 l0 -140 -29 0 c-25 0 -37 13 -102 109 -40 60 -76 108 -79
106 -3 -2 -4 -44 -2 -94 3 -82 5 -91 23 -91 10 0 19 -7 19 -15 0 -12 -13 -15
-60 -15 -47 0 -60 3 -60 15 0 8 7 15 15 15 13 0 15 21 15 125 0 104 -2 125
-15 125 -14 0 -21 21 -10 32 3 3 22 5 43 4 36 -1 39 -5 107 -108z m359 106
c11 -4 16 -19 16 -45 0 -32 -3 -39 -20 -39 -13 0 -20 7 -20 20 0 39 -73 39
-85 -1 -8 -25 10 -48 55 -67 58 -23 80 -50 80 -96 0 -46 -5 -55 -40 -79 -24
-17 -85 -22 -127 -11 -19 5 -23 13 -23 50 0 37 3 44 20 44 15 0 20 -7 20 -25
0 -18 7 -26 26 -31 37 -9 74 10 74 39 0 28 -21 48 -69 67 -49 20 -71 48 -71
91 0 52 44 89 105 89 24 0 50 -3 59 -6z m131 -4 c3 -6 3 -30 -1 -55 -5 -35
-10 -45 -25 -45 -16 0 -19 8 -19 55 0 48 3 55 19 55 11 0 23 -5 26 -10z
m-7175 -45 c0 -32 -5 -47 -15 -52 -21 -8 -24 -4 -31 50 -7 46 -6 47 20 47 24
0 26 -3 26 -45z m7247 0 c-5 -52 -10 -60 -32 -52 -10 5 -15 20 -15 52 0 42 2
45 26 45 26 0 26 -2 21 -45z"/>
<path d="M2538 2915 c-2 -2 -1 -28 0 -59 3 -50 6 -56 26 -56 35 0 57 22 58 58
1 18 -2 35 -5 38 -7 7 -75 23 -79 19z"/>
<path d="M2800 2859 c0 -55 2 -59 23 -59 31 0 55 19 62 50 7 34 -5 50 -49 60
l-36 8 0 -59z"/>
<path d="M3068 2906 c-57 -43 -48 -190 13 -221 25 -13 33 -14 63 -1 34 14 39
22 51 82 8 43 -13 122 -38 139 -26 18 -66 19 -89 1z"/>
<path d="M3380 2854 l0 -56 28 7 c80 20 81 105 1 105 -29 0 -29 -1 -29 -56z"/>
<path d="M5143 2908 c-39 -19 -54 -95 -32 -171 8 -30 18 -42 45 -53 50 -21 89
1 105 58 31 117 -33 207 -118 166z"/>
<path d="M7417 2848 c-23 -75 -22 -78 19 -78 l36 0 -7 37 c-4 21 -12 50 -19
67 l-12 29 -17 -55z"/>
<path d="M8140 2895 c-28 -30 -38 -106 -20 -160 21 -63 110 -82 138 -28 30 58
20 164 -19 196 -30 24 -71 21 -99 -8z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
